import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHtppInterceptorServiceService } from './shared/service/auth-htpp-interceptor-service.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { YardInventoryEditComponent } from './inventory/components/yard-inventory-edit/yard-inventory-edit.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    NoopAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule,
    MatAutocompleteModule,
  ],
  entryComponents: [YardInventoryEditComponent],

  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthHtppInterceptorServiceService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
