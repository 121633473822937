import { Injectable } from '@angular/core';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class LogicalfunctionService {
  constructor() {}

  public filteredDataString(control: FormControl, list): Observable<any> {
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => (value ? this.filteredString(value, list) : list.slice()))
    );
  }

  public filteredDataNumber(control: FormControl, list): Observable<any> {
    return control.valueChanges.pipe(
      startWith(''),
      map((value) => (value ? this.filteredNumber(value, list) : list.slice()))
    );
  }
  public filteredString(value: string, list) {
    const filterValue = value.toLowerCase();
    return list.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  public filteredNumber(value: number, list) {
    const filterValue = value.toLocaleString();
    return list.filter(
      (option) => option.toLocaleString().indexOf(filterValue) === 0
    );
  }

  public filteredArrayWithJsonValue(value, data) {
    let values;
    values = value.map((x) => x[data]);
    return values.filter((x, i, a) => a.indexOf(x) === i);
  }

  // public filterMulti(details,formCtrl:FormControl,filetered,_onDestroy) {
  //   formCtrl.valueChanges
  //     .pipe(takeUntil(_onDestroy))
  //     .subscribe(() => {
  //       this.filterMultipleValue(details,formCtrl,filetered);
  //     });
  // }

  public filterMultipleValueStringwithName(
    details,
    formCtrl: FormControl,
    filetered
  ) {
    if (!details) {
      return;
    }
    let search = formCtrl.value;

    if (!search) {
      filetered.next(details.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    filetered.next(
      details.filter((x) => x.partName.toLowerCase().indexOf(search) > -1)
    );
  }
  public filterMultipleValueStringwithSubCatory(
    details,
    formCtrl: FormControl,
    filetered
  ) {
    if (!details) {
      return;
    }
    let search = formCtrl.value;

    if (!search) {
      filetered.next(details.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    filetered.next(
      details.filter(
        (x) => x.subCategoryName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  public filterMultipleValueString(details, formCtrl: FormControl, filtered) {
    if (!details) {
      return;
    }

    let search = formCtrl.value;
    if (!search) {
      filtered.next(details.slice());
      return;
    }

    search = search.toLowerCase();

    filtered.next(
      details.filter((x) => {
        if (typeof x === 'string' || x instanceof String) {
          return x.toLowerCase().indexOf(search) > -1;
        }
        return false;
      })
    );
  }

  public filterMultipleValueNumber(details, formCtrl: FormControl, filetered) {
    if (!details) {
      return;
    }
    let search = formCtrl.value;
    if (!search) {
      filetered.next(details.slice());
      return;
    } else {
      search = search.toLocaleString();
    }
    filetered.next(
      details.filter((x) => x.toLocaleString().indexOf(search) > -1)
    );
  }

  public filteredArray(value) {
    let values;
    values = value.map((x) => x);
    return values.filter((x, i, a) => a.indexOf(x) === i);
  }

  public filteredArrayWithJsonValueTwo(value, data1, data2) {
    let values;
    values = value.map((x) => x[data1][data2]);
    return values.filter((x, i, a) => a.indexOf(x) === i);
  }
  public filteredArrayWithJsonValueTwos(value, data1, data2) {
    let values;
    // Check if value is defined and not null
    if (value && value.length > 0) {
      // Use optional chaining to handle potential undefined or null values
      values = value.map((x) => x[data1]?.[data2]);
      // Filter out duplicate values
      values = values.filter((x, i, a) => a.indexOf(x) === i);
    } else {
      values = [];
    }
    return values;
  }

  public filteredArrayWithJsonValueFour(value, data1, data2, data3) {
    let values;
    values = value.map((x) => x[data1][data2][data3]);
    return values.filter((x, i, a) => a.indexOf(x) === i);
  }

  checkBoxEnable(index, singleBox, option, data, value1, value2, value3) {
    let j;
    let bulckBox;
    let bulckCheck;
    let checked;
    console.log('value1', value3);

    // tslint:disable-next-line: triple-equals
    if (
      Number(value1) &&
      value1 != undefined &&
      Number(value1) != 0 &&
      Number(value2) &&
      value2 != undefined &&
      Number(value2) != 0 &&
      Number(value3) &&
      value3 !== undefined &&
      Number(value3) !== 0
    ) {
      singleBox[index] = true;
    } else {
      singleBox[index] = false;
    }
    j = 0;
    singleBox.forEach((res) => {
      // tslint:disable-next-line: triple-equals
      if (res == true) {
        j++;
      }
    });
    bulckCheck = false;
    checked = false;
    // tslint:disable-next-line: triple-equals
    if (j == option.length) {
      bulckBox = true;
    } else {
      bulckBox = false;
    }
    let k = 0;
    if (data.length > 0) {
      data.forEach((res) => {
        if (res === option[index]) {
          data.splice(k, 1);
        }
        k++;
      });
    }
    return { singleBox, bulckBox, checked, bulckCheck, data };
  }

  singleCheck(event, index, length, data, value) {
    let j = 0;
    let bulckCheck;
    if (event.checked) {
      data.push(value);
    } else {
      data.forEach((res) => {
        if (res === value) {
          data.splice(j, 1);
        }
        j++;
      });
    }
    // tslint:disable-next-line: triple-equals
    if (length == data.length) {
      bulckCheck = true;
    } else {
      bulckCheck = false;
    }
    return { data, bulckCheck };
  }

  bulckChecked(event, option) {
    // tslint:disable-next-line: prefer-const
    let data = [];
    // tslint:disable-next-line: prefer-const
    let checked = [];
    if (event.checked) {
      for (let i = 0; i < option.length; i++) {
        checked[i] = true;
      }
      option.forEach((res) => {
        data.push(res);
      });
    } else {
      for (let i = 0; i < option.length; i++) {
        checked[i] = false;
      }
      option.forEach((res) => {
        data.pop();
      });
    }
    return { checked, data };
  }

  bulckCheckedSingValue(event, option, value) {
    // tslint:disable-next-line: prefer-const
    let data = [];
    // tslint:disable-next-line: prefer-const
    let checked = [];
    if (event.checked) {
      for (let i = 0; i < option.length; i++) {
        checked[i] = true;
      }
      data.push(value);
    } else {
      for (let i = 0; i < option.length; i++) {
        checked[i] = false;
      }
      option.forEach((res) => {
        data.pop();
      });
    }
    return { checked, data };
  }

  gstCalculation(percentage, amount, qty) {
    let gstAmount;
    let perKgAmount;
    let totalAmount;
    // console.log('amount',amount);
    // console.log(qty,"qty");

    amount = Number(amount).toFixed(2);
    if (Number(percentage) && Number(percentage) > 0) {
      const gst = (Number(amount) * Number(percentage)) / 100;
      const perKgCost = Number(amount) + Number(gst);
      const totalCost = Number(qty) * perKgCost;
      gstAmount = Number(gst).toFixed(2);
      perKgAmount = Number(perKgCost).toFixed(2);
      totalAmount = Number(totalCost).toFixed(2);
    } else if (Number(percentage) === 0) {
      gstAmount = 0;
      perKgAmount = 0;
      totalAmount = 0;
      gstAmount = 0;
      const totalCost = Number(qty) * amount;
      perKgAmount = amount;
      totalAmount = Number(totalCost).toFixed(2);
    } else {
      gstAmount = 0;
      perKgAmount = 0;
      totalAmount = 0;
    }

    return { gstAmount, perKgAmount, totalAmount };
  }
  gstCalculation1(amount, qty) {
    let gstAmount;
    let perKgAmount;
    let totalAmount;
    let percentage = 0;
    console.log('amount', amount);

    amount = Number(amount).toFixed(2);
    if (Number(percentage) && Number(percentage) > 0) {
      const gst = (Number(amount) * Number(percentage)) / 100;
      const perKgCost = Number(amount) + Number(gst);
      const totalCost = Number(qty) * perKgCost;
      gstAmount = Number(gst).toFixed(2);
      console.log(gst);

      perKgAmount = Number(perKgCost).toFixed(2);
      totalAmount = Number(totalCost).toFixed(2);
    } else if (Number(percentage) === 0) {
      gstAmount = 0;
      perKgAmount = 0;
      totalAmount = 0;
      gstAmount = 0;
      const totalCost = Number(qty) * amount;
      perKgAmount = amount;
      totalAmount = Number(totalCost).toFixed(2);
    } else {
      gstAmount = 0;
      perKgAmount = 0;
      totalAmount = 0;
    }

    return { gstAmount, perKgAmount, totalAmount };
  }
}
