import { Component, OnInit } from '@angular/core';
import { Calibration } from '../../model/calibration';
import { Vendor } from 'aws-sdk/clients/directconnect';
import { FormControl, FormGroup } from '@angular/forms';
import { CalibrationService } from '../../service/calibration.service';
import { LogicalfunctionService } from 'src/app/shared/service/logicalfunction.service';
import { YardInventory } from '../../model/yard-inventory';
import { Observable } from 'rxjs';
import { GstMasterService } from 'src/app/master/service/gst-master.service';
import { NotifierService } from 'angular-notifier';
import { Router } from "@angular/router";


@Component({
  selector: 'app-yard-inventory-edit',
  templateUrl: './yard-inventory-edit.component.html',
  styleUrls: ['./yard-inventory-edit.component.scss'],
})
export class YardInventoryEditComponent implements OnInit {
  public yardInventory: YardInventory;
  public calibrationList;
  public vendorMasterList: Vendor[];
  public calibrationForm: FormGroup;
  public calibrationForm1: FormGroup;
  public uniqueVendorName = [];
  public uniqueInvoiceNo = [];
  public calibrationArray = [];
  public id = [];
  public yards = [];
  checkedList;
  public checkBox = false;
  public save = false;
  public bulckCheck = false;
  public viewTable = false;
  public vendorList = [];
  minDate = new Date(2019, 0, 1);
  maxDate = new Date();
  public checked = [];
  public store = false;
  public bulkcheck;
  public uniqueGST;
  public totalAmount: number = 0;
  public totalAmountWithGst: number = 0;

  public itemList = [];
  vendorNameControl = new FormControl();
  invoiceNoControl = new FormControl();
  filterVendorNameList: Observable<any[]>;
  filteredinvoiceNo: Observable<any[]>;

  constructor(
    private calibrationService: CalibrationService,
    private logicalService: LogicalfunctionService,
    private notifier: NotifierService,
    private gstService: GstMasterService,
    private router: Router

  ) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    this.yardInventory = new YardInventory();
    this.getAllCalibrationProcessing();
    this.getGSTDetails();
  }

  getAllCalibrationProcessing() {
    this.uniqueVendorName = [];
    this.save = false;
    this.checkedList = [];
    this.bulckCheck = false;
    let data = { status: 'pending' };
    this.calibrationService.getAllPendingfromgate(data).subscribe(
      (data: any) => {
        this.calibrationList = data.result.filter(
          (item) => item.vendorId !== null
        );
        this.uniqueVendorName =
          this.logicalService.filteredArrayWithJsonValueTwo(
            this.calibrationList,
            'vendorId',
            'vendorName'
          );

        this.filterVendorNameList = this.logicalService.filteredDataString(
          this.vendorNameControl,
          this.uniqueVendorName
        );
      },
      (err) => {
        this.save = true;
        console.log('err', err);
      }
    );
  }
  invoiceNo(process) {
    if (this.calibrationList && this.calibrationList.length > 0) {
      let data = this.calibrationList.filter(
        (x) => x.vendorId.vendorName === process
      );
      if (data.length > 0) {
        this.yardInventory.vendorId = data[0].vendorId._id;
        this.uniqueInvoiceNo = this.logicalService.filteredArrayWithJsonValue(
          data,
          'invoiceNo'
        );
        this.filteredinvoiceNo = this.logicalService.filteredDataString(
          this.invoiceNoControl,
          this.uniqueInvoiceNo
        );
      } else {
        console.error('No matching vendor found');
      }
    } else {
      console.error('Calibration list is empty or not loaded');
    }
  }
  refresh() {}
  searchDetails() {
    this.viewTable = true;
    this.calibrationService
      .getAllPendingfromgate(this.yardInventory)
      .subscribe((doc: any) => {
        this.itemList = doc.result;
      });
  }
  selectAllParts(event) {
    if (event.checked) {
      this.id = [];
      for (let i = 0; i < this.itemList.length; i++) {
        this.checked[i] = true;
      }
      this.itemList.forEach((res) => {
        this.id.push(res._id);
      });
      this.store = true;
    } else {
      this.itemList.forEach((res) => {
        this.id.pop();
      });
      for (let i = 0; i < this.itemList.length; i++) {
        this.checked[i] = false;
      }
      this.store = false;
    }
  }
  selectParts(event, id, j) {
    let i = 0;
    if (event.checked) {
      this.checked[j] = true;
      this.id.push(id);
    } else {
      this.id.forEach((res) => {
        if (res === id) {
          this.checked[j] = false;
          this.id.splice(i, 1);
        }
        i++;
      });
    }
    if (this.id.length > 0) {
      this.store = true;
    } else {
      this.store = false;
    }
    if (this.id.length === this.itemList.length) {
      this.bulkcheck = true;
    } else {
      this.bulkcheck = false;
    }
  }
  getGSTDetails() {
    let gst = [];
    this.gstService.getActiveGstMaster().subscribe(
      (data: any) => {
        data.result.forEach((res) => {
          gst.push(res.gst);
        });
        this.uniqueGST = Array.from(new Set(gst));
      },
      (error) => {
        console.log('error', error);
      }
    );
  }
  gstCalculation(yard: number, i: any, j: number): number | void {
    if (
      i &&
      i.totalAmount &&
      typeof yard === 'number' &&
      yard >= 0 &&
      this.itemList[j]
    ) {
      const totalAmount = parseFloat(i.totalAmount);
      const gstAmount = (totalAmount * yard) / 100;
      const totalAmountWithGst = totalAmount + gstAmount;
      this.itemList[j].totalAmountWithGst = totalAmountWithGst;
      this.calculateTotalAmounts();
      return totalAmountWithGst;
    } else {
      console.error(
        `Invalid input or item at index ${j} is undefined or null.`
      );
    }
  }

  calculateTotalAmounts() {
    this.totalAmount = this.itemList.reduce(
      (sum, item) => sum + parseFloat(item.totalAmount || 0),
      0
    );
    this.totalAmountWithGst = this.itemList.reduce(
      (sum, item) => sum + parseFloat(item.totalAmountWithGst || 0),
      0
    );
  }

  saveDetails() {
    console.log(this.id, this.itemList);
    let data = { yard: this.yardInventory, part: this.itemList };
    this.calibrationService
      .saveCalibrationyardInventory(data)
      .subscribe((doc: any) => {
        this.notifier.notify('success', 'Saved Successfully');
        this.cancel();
      });
  }
  cancel() {
    this.yardInventory = new YardInventory();
    this.yardInventory.status = 'pending';
    this.getAllCalibrationProcessing();
    this.getGSTDetails();
    this.checkBox = false;
    this.save = false;
    this.bulckCheck = false;
    this.viewTable = false;
    this.id = [];
    this.itemList = [];
  }
  goBack() {
    this.router.navigateByUrl("/trackingsystem/inventory/yard-inventory");
  }
}
