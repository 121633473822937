import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GstMaster } from '../model/gst-master';

@Injectable({
  providedIn: 'root'
})
export class GstMasterService {

  public baseUrl: string = environment.apiBaseUrl + '/gstMaster';
  constructor(public http: HttpClient) { }

  getAllGstMasters = () => {
    return this.http.get(this.baseUrl + '/getAll');
  }

  getActiveGstMaster = () => {
    return this.http.get(this.baseUrl + '/active');
  }

  saveGstMaster = (gstMaster: GstMaster) => {
    return this.http.post(this.baseUrl + '/save', gstMaster);
  }

  updateGstMaster = (gstMaster: GstMaster) => {
    return this.http.patch(this.baseUrl + '/update', gstMaster);
  }

  deleteGstMaster = (id) => {
      const params = new HttpParams().set('id', id);
      return this.http.delete(this.baseUrl + '/delete', { params });
  }

  updateGstMasterStatus = (gstMaster: GstMaster) => {
    return this.http.put(this.baseUrl + '/status', gstMaster);
  }
}

