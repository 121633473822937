<mat-card class="example-card" style="width: 80%; height: 100px">
    <mat-card-header>
      <mat-card-title>
        <mat-card class="example-card2">
          <mat-card-header>
            <mat-card-title>Edit Yard Calibration</mat-card-title>
          </mat-card-header>
        </mat-card>
      </mat-card-title>
    </mat-card-header>
    <div class="row">
      <div class="col col-5">
        <mat-form-field class="example-full-width">
          <input
            matInput
            type="text"
            placeholder="Vendor Name"
            name="vendorName"
            [formControl]="vendorNameControl"
            [(ngModel)]="yardInventory.vendorName"
            (ngModelChange)="invoiceNo(yardInventory.vendorName)"
            [matAutocomplete]="auto1"
          />
          <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
            <mat-option
              *ngFor="let partName of filterVendorNameList | async"
              [value]="partName"
            >
              {{ partName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col col-5">
        <mat-form-field class="example-full-width">
          <input
            matInput
            type="text"
            placeholder="Invoice No"
            name="invoiceNo"
            [formControl]="invoiceNoControl"
            [(ngModel)]="yardInventory.invoiceNo"
            [matAutocomplete]="auto2"
          />
          <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
            <mat-option
              *ngFor="let partName of filteredinvoiceNo | async"
              [value]="partName"
            >
              {{ partName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col col-2 button-group">
        <button mat-mini-fab matTooltip="Search" (click)="searchDetails()">
          <mat-icon>search</mat-icon>
        </button>
        <button mat-mini-fab matTooltip="Refresh" (click)="cancel()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <button
          mat-raised-button
          color="primary"
          matTooltip="Go Back"
          (click)="goBack()"
        >
          <mat-icon>arrow_back</mat-icon>
          Back
        </button>
      </div>
    </div>
  </mat-card>
  
  <div class="container-fluid">
    <mat-card class="example-card" *ngIf="viewTable">
      <mat-card-header>
        <mat-card-title>
          <mat-card class="example-card2">
            <mat-card-header>
              <mat-card-title>Part Details</mat-card-title>
            </mat-card-header>
          </mat-card>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-responsive" style="width: 100%">
          <table class="table">
            <thead>
              <tr>
                <th>No</th>
                <th>Item Name</th>
                <th>item Id</th>
                <th>Category</th>
                <th>Invoice No</th>
                <th>Amount</th>
                <th>Gst</th>
                <th>Total Amount</th>
                <th>
                  <mat-checkbox
                    name="bulkcheck"
                    [(ngModel)]="bulkcheck"
                    (change)="selectAllParts($event)"
                  ></mat-checkbox>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let yard of itemList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ yard.itemMasterId.itemName }}</td>
                <td>{{yard.itemId}}</td>
                <td>{{ yard.itemMasterId.category }}</td>
             
                <td>{{ yard.invoiceNo }}</td>
                <td>
                  <mat-form-field class="example-full">
                    <input
                      matInput
                      type="text"
                      name="totalAmount{{ i }}"
                      [(ngModel)]="yard.totalAmount"
                      appNumberValidation
                      autocomplete="off"
                      required
                      (ngModelChange)="calculateTotalAmounts()"
                    />
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field class="example-full">
                    <mat-select
                      placeholder="GST"
                      name="gst"
                      [(ngModel)]="yard.gst"
                      (selectionChange)="gstCalculation(yard.gst, yard, i)"
                      required
                    >
                      <mat-option *ngFor="let gst of uniqueGST" [value]="gst">
                        {{ gst }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>{{ yard.totalAmountWithGst }}</td>
                <td>
                  <mat-checkbox
                    [checked]="checked[i]"
                    [(ngModel)]="checked[i]"
                    (change)="selectParts($event, yard._id, i)"
                  ></mat-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row total-amount-row">
            <div class="col total-amount">
              <h3>Total Amount: {{ totalAmount }}</h3>
              <h3>Total Amount with GST: {{ totalAmountWithGst }}</h3>
              <button mat-raised-button color="primary" (click)="saveDetails()">
                Save
              </button>
              <button mat-raised-button color="warn" (click)="cancel()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#1459cd" type="ball-spin-clockwise" [fullScreen]="true">
    <p style="color: white">Loading...</p>
  </ngx-spinner>
  
  <notifier-container></notifier-container>
  